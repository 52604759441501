<img src="assets/bg-tenant-select.svg" class="bg-tenant" />

<div class="vitamui-header">
  <div class="vitamui-container">
    <h2>
      {{ 'SELECT_TENANT.MY_TENANTS' | translate }}
    </h2>
  </div>
</div>

<vitamui-common-tenant-select-content [tenants]="tenants" [isModalMenu]="false"></vitamui-common-tenant-select-content>
