/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { UserApiService } from '../../api/user-api.service';
import { ApplicationService } from '../../application.service';
import { AuthService } from '../../auth.service';
import { Application } from '../../models';
import { AlertAnalytics } from '../../models/user/user-alerts.interface';
import { User } from '../../models/user/user.interface';
import { buildAlertUrl } from './user-alerts.util';

@Injectable({ providedIn: 'root' })
export class UserAlertsService {
  // Set by the analytics resolver
  private userAlerts = new BehaviorSubject<AlertAnalytics[]>(null);
  private seeMoreAlerts = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private userApiService: UserApiService,
    private appService: ApplicationService,
  ) {}

  public getUserAlerts(): AlertAnalytics[] {
    return this.userAlerts.value;
  }

  public setUserAlerts(alerts: AlertAnalytics[]): void {
    this.userAlerts.next(alerts);
  }

  public getUserAlerts$(): Observable<AlertAnalytics[]> {
    return this.userAlerts.asObservable();
  }

  public seeMoreAlerts$(): Observable<unknown> {
    return this.seeMoreAlerts.asObservable();
  }

  public setSeeMoreAlerts(seeMore: boolean): void {
    this.seeMoreAlerts.next(seeMore);
  }

  public openAlert(alert: AlertAnalytics): Observable<Application> {
    return this.removeUserAlertById(alert.id).pipe(
      mergeMap(() => this.appService.getAppById(alert?.applicationId)),
      tap((app: Application) => (window.location.href = buildAlertUrl(app, alert))),
    );
  }

  public removeUserAlertById(alertId: string): Observable<AlertAnalytics[]> {
    const alerts: AlertAnalytics[] = this.userAlerts.value;
    const index = alerts.findIndex((a: AlertAnalytics) => a.id === alertId);

    if (index !== -1) {
      alerts.splice(index, 1);
      return this.userApiService.analytics({ alerts }).pipe(
        tap((user: User) => {
          this.authService.user.analytics = user.analytics;
          this.userAlerts.next(user.analytics.alerts);
        }),
        map((user: User) => user.analytics.alerts),
        take(1),
      );
    } else {
      return of(alerts);
    }
  }
}
