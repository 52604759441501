<div class="vitamui-input" [class.disabled]="disabled">
  <label class="mt-1">
    {{ placeholder }}
    <span *ngIf="required" class="required-marker">*</span>
  </label>
  <textarea
    [required]="required"
    [disabled]="disabled"
    [attr.maxlength]="maxlength"
    [rows]="rows"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    #textarea
  ></textarea>
  <mat-spinner diameter="25" color="accent"></mat-spinner>
</div>
<div class="message-area">
  @if (control.valid) {
    <ng-content select="vitamui-hint"></ng-content>
  }
  <ng-content></ng-content>
</div>
