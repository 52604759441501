<ng-container *ngIf="display === 'KEY-VALUE-OBJECTS'">
  <vitamui-common-data
    *ngFor="let entry of entries(displayObject.value)"
    [label]="displayObject.displayRule.ui.label + ' [' + entry[0] + ']'"
    [value]="entry[1]"
  >
  </vitamui-common-data>
</ng-container>

<ng-container *ngIf="display === 'ROWS'">
  <div *ngTemplateOutlet="content"></div>
</ng-container>

<vitamui-common-accordion *ngIf="display === 'ACCORDION'" [opened]="displayObject.open">
  <span class="title">{{ displayObject.displayRule.ui.label | translate | empty }}</span>
  <div *ngIf="favoriteEntry" class="title-extra v-col align-items-center favorite-entry">
    {{ favoritePath | translate }}: {{ favoriteEntry[1] | empty }}
  </div>
  <div class="content">
    <div *ngTemplateOutlet="content"></div>
  </div>
</vitamui-common-accordion>

<ng-template #content>
  <div *ngFor="let column of rows" class="v-row">
    <div *ngFor="let childDisplayObject of column" class="v-col">
      <div *ngIf="!childDisplayObject"></div>
      <ng-container *ngIf="childDisplayObject">
        <ng-container #displayObject [ngSwitch]="childDisplayObject.type">
          <vitamui-common-group *ngSwitchCase="DisplayObjectType.GROUP" [displayObject]="childDisplayObject"></vitamui-common-group>
          <vitamui-common-list *ngSwitchCase="DisplayObjectType.LIST" [displayObject]="childDisplayObject"></vitamui-common-list>
          <vitamui-common-primitive
            *ngSwitchCase="DisplayObjectType.PRIMITIVE"
            [displayObject]="childDisplayObject"
          ></vitamui-common-primitive>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div *ngIf="displayObject.value === null || displayObject.value === undefined">{{ displayObject.value | empty }}</div>
</ng-template>
