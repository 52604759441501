/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FavoriteEntryService } from '../../../object-viewer/services/favorite-entry.service';
import { DisplayObjectType } from '../../../object-viewer/types';
import { EditObject } from '../../models/edit-object.model';

@Component({
  selector: 'vitamui-common-list-editor',
  templateUrl: './list-editor.component.html',
  styleUrls: ['./list-editor.component.scss'],
})
export class ListEditorComponent implements OnChanges {
  @Input() editObject: EditObject;

  favoriteEntry: [key: string, value: any];
  favoritePath: string;

  readonly DisplayObjectType = DisplayObjectType;

  constructor(private favoriteEntryService: FavoriteEntryService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { editObject } = changes;

    if (editObject) this.favoriteEntryService.favoriteEntry(this.editObject);
  }

  addFirst(): void {
    if (this.editObject.children.length === 0) this.editObject.actions.add.handler();
  }

  add(event: Event): void {
    this.editObject.actions.add.handler();
    event.stopPropagation();
  }

  removeAt(event: Event, i: number): void {
    this.editObject.actions.removeAt.handler(i);
    event.stopPropagation();
  }
}
