/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

export const VITAMUI_DURATION_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VitamUIDurationInputComponent),
  multi: true,
};

@Component({
  selector: 'vitamui-common-vitamui-duration-input',
  templateUrl: './vitamui-duration-input.component.html',
  styleUrls: ['./vitamui-duration-input.component.scss'],
  providers: [VITAMUI_DURATION_INPUT_VALUE_ACCESSOR],
})
export class VitamUIDurationInputComponent implements ControlValueAccessor {
  public form: FormGroup;

  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  constructor(formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      days: null,
      hours: null,
      minutes: null,
    });
    this.form.valueChanges.subscribe((val) => {
      let duration;
      if (val.days == null && val.hours == null && val.minutes == null) {
        duration = null;
      } else {
        duration = moment.duration(val).toISOString();
      }
      this.onChange(duration);
    });
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(value: string) {
    const momentDuration = moment.duration(value);
    this.form.get('days').reset(momentDuration.days() || null, { emitEvent: false });
    this.form.get('hours').reset(momentDuration.hours() || null, { emitEvent: false });
    this.form.get('minutes').reset(momentDuration.minutes() || null, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
