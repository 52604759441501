<img src="assets/bg-customer-select.svg" class="customer-bg" alt="customer select" />

<div class="vitamui-header">
  <div class="vitamui-container">
    <h2>
      {{ 'SUBROGATION.PREHOME.ORGANIZATIONS' | translate }}
    </h2>
  </div>
</div>

<vitamui-common-customer-select-content [customers]="customers" [isModalMenu]="false"></vitamui-common-customer-select-content>
