/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from '../models/units/unit.interface';

/**
 * Extracts the Title (or Description) from a Unit.
 * It defaults to "Title" (or "Description") attributes if present.
 * Otherwise, it'll get the French translation if present. By default, the Title_.fr, otherwise in non-lower-case (ex: Title_.FR or Title_.Fr or Title_.fR) if present.
 * Otherwise, it'll get the English translation if present. By default, the Title_.en, otherwise in non-lower-case (ex: Title_.EN or Title_.En or Title_.eN) if present.
 * Otherwise, it'll get any available translation.
 * Otherwise, empty string.
 */
@Pipe({ name: 'unitI18n' })
export class UnitI18nPipe implements PipeTransform {
  transform(unit: Unit, attribute: 'Title' | 'Description') {
    return getUnitI18nAttribute(unit, attribute);
  }
}

export function getUnitI18nAttribute(unit: Unit, attribute: 'Title' | 'Description') {
  if (unit[attribute]) {
    return unit[attribute];
  }
  const i18nAttribute = `${attribute}_`;
  const unitElement = unit[i18nAttribute];
  if (unitElement) {
    const keys = Object.keys(unitElement);
    for (const lang of ['fr', 'en']) {
      if (unitElement[lang]) {
        return unitElement[lang];
      }
      const langKey = keys.filter((key) => lang === key.toLowerCase()).pop();
      if (langKey) {
        return unitElement[langKey];
      }
    }
    if (keys.length) {
      return unitElement[keys[0]];
    }
  }
  return '';
}
