<mat-toolbar color="primary" class="header">
  <div class="header-logo">
    <a class="d-flex" href="{{ portalUrl }}">
      <img alt="header logo" [src]="headerLogoUrl" />
    </a>
  </div>

  <div class="header-actions">
    <ng-content select="[extended-content]"></ng-content>

    <div class="d-flex align-items-center user-alerts" *ngIf="userAlerts">
      <vitamui-common-user-alerts-menu
        [userAlerts]="userAlerts"
        [hasMoreAlerts]="hasMoreAlerts"
        (openAlert)="openAlert($event)"
        (closeAlert)="removeAlert($event)"
        (seeMoreAlerts)="seeMoreAlerts()"
      >
      </vitamui-common-user-alerts-menu>
    </div>

    <div class="d-flex align-items-center" *ngIf="hasSiteSelection">
      <vitamui-common-select-site></vitamui-common-select-site>
    </div>

    <div class="d-flex align-items-center" *ngIf="hasLangSelection">
      <vitamui-common-select-language [displayMode]="'select'"></vitamui-common-select-language>
    </div>

    <div class="d-flex align-items-center" [class.selection]="hasTenantSelection || hasCustomerSelection">
      <!-- tenant selection -->
      <vitamui-common-item-select
        *ngIf="hasTenantSelection"
        [label]="'SELECT_TENANT.SELECT' | translate"
        [selectedLabel]="'SELECT_TENANT.SELECTED' | translate"
        [items]="appTenants"
        [selectedItem]="selectedTenant"
        (itemSelected)="updateTenant($event)"
      >
      </vitamui-common-item-select>

      <!-- customer selection -->
      <vitamui-common-item-select
        *ngIf="hasCustomerSelection"
        [label]="'SELECT_CUSTOMER.SELECT' | translate"
        [selectedLabel]="'SELECT_CUSTOMER.SELECTED' | translate"
        [items]="customers"
        [selectedItem]="selectedCustomer"
        (itemSelected)="updateCustomer($event)"
      >
      </vitamui-common-item-select>
    </div>

    <div class="d-flex align-items-center pl-3 mr-4 account" [matMenuTriggerFor]="accountMenu">
      <vitamui-common-user-photo class="mr-2" [photo]="currentUser?.photo"></vitamui-common-user-photo>
      <div class="mx-2">
        <div class="text caption bold">{{ 'HEADER.PROFILE' | translate }}</div>
        <div class="text normal">{{ currentUser?.firstname }} {{ currentUser.lastname }}</div>
      </div>

      <div class="ml-3">
        <i class="vitamui-icon vitamui-icon-chevron-down"></i>
      </div>

      <div class="account-menu">
        <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before">
          <ng-container>
            <button mat-menu-item [routerLink]="['/account']" *ngIf="hasAccountProfile">{{ 'HEADER.MY_ACCOUNT' | translate }}</button>
            <button mat-menu-item (click)="enabledSubrogation()" *ngIf="!!!currentUser?.superUser">
              {{ 'HEADER.NAVIGATE_AS' | translate }}
            </button>
            <button mat-menu-item (click)="logout()">{{ 'HEADER.LOGOUT' | translate }}</button>
          </ng-container>
        </mat-menu>
      </div>
    </div>

    <div>
      <button mat-mini-fab class="apps-button" (click)="openMenu()" aria-label="applications button">
        <i class="vitamui-icon vitamui-icon-apps-colored"
          ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
          ><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
          ><span class="path9"></span
        ></i>
      </button>
    </div>
  </div>
</mat-toolbar>
