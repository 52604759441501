<vitamui-common-confirm-dialog
  *ngIf="dialogType === 'delete'"
  dialogTitle="{{ 'CONFIRM_DIALOG.DELETE_TITLE' | translate: { objectType: objectType } }}"
  confirmLabel="{{ 'COMMON.CONFIRM' | translate }}"
  cancelLabel="{{ 'COMMON.CANCEL' | translate }}"
>
  {{ 'CONFIRM_DIALOG.DELETE' | translate: { objectType: objectType, objectName: objectName } }}
</vitamui-common-confirm-dialog>

<vitamui-common-confirm-dialog
  *ngIf="dialogType === 'changeTab'"
  dialogTitle="{{ 'CONFIRM_DIALOG.UNSAVED_CHANGES_TITLE' | translate }}"
  confirmLabel="{{ 'COMMON.YES' | translate }}"
  cancelLabel="{{ 'COMMON.NO' | translate }}"
>
  {{ 'CONFIRM_DIALOG.SAVE' | translate }}
</vitamui-common-confirm-dialog>
