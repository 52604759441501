<div class="vitamui-input" [class.disabled]="date.disabled">
  <label>
    {{ label | translate }}
    <span *ngIf="isRequired" class="required-marker">*</span>
  </label>
  <input class="hidden-input" [matDatepicker]="datepicker" [value]="datePickerValue" (dateChange)="setYearMonthAndDay($event.value)" />
  <input
    #vitamUIInput
    [disabled]="date.disabled"
    [required]="isRequired"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [ngModel]="date.value"
    (ngModelChange)="onTextChange($event)"
  />
  <mat-datepicker-toggle [for]="datepicker" matSuffix>
    <mat-icon matDatepickerToggleIcon><i class="vitamui-icon vitamui-icon-calendar"></i></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker
    #datepicker
    panelClass="picker"
    [startView]="startView"
    [disabled]="date.disabled"
    (yearSelected)="setYear($event)"
    (monthSelected)="setYearAndMonth($event)"
  ></mat-datepicker>
</div>
<div #hintArea class="hint-area">
  <div class="info" *ngIf="date.valid || date.disabled || date.pristine">
    <i class="vitamui-icon vitamui-icon-info"></i>
    <mat-hint *ngIf="hint"> {{ hint | translate }} </mat-hint>
    <mat-hint *ngIf="!hint">
      {{ 'DATE.EXPECTED_FORMAT' | translate: { format: 'DATE.FORMAT.' + pickerType.toUpperCase() | translate } }}
    </mat-hint>
  </div>
  <div class="error" *ngIf="date.dirty && !!date.errors?.pattern">
    <i class="vitamui-icon vitamui-icon-anomalie"></i>
    <mat-hint> {{ 'DATE.ERROR.INCORRECT_FORMAT' | translate }} </mat-hint>
  </div>
  <div class="error" *ngIf="date.dirty && !!date.errors?.required">
    <i class="vitamui-icon vitamui-icon-anomalie"></i>
    <mat-hint> {{ 'DATE.ERROR.REQUIRED_FIELD' | translate }} </mat-hint>
  </div>
</div>
