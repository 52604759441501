<a class="d-flex align-items-center" href="{{ applicationUrl }}" (click)="openApplication.emit(application)">
  <div class="d-flex align-items-center mandatory">
    <i [ngClass]="application.icon" class="app-icon d-flex justify-content-center align-items-center"></i>
    <span
      class="pl-2 appName"
      [innerHTML]="'APPLICATION.' + application.identifier + '.NAME' | translate: { default: application.name } | highlight: hlCriteria"
    ></span>
    <span class="hover-focus pl-2">-</span>
    <span class="hover-focus pl-2 tooltip">{{
      'APPLICATION.' + application.identifier + '.TOOLTIP' | translate: { default: application.tooltip }
    }}</span>
  </div>
  <div class="ml-auto focus d-flex justify-content-end">
    <span class="material-icons">keyboard_return</span>
    <span class="pl-2">{{ 'MENU_APPLICATION_TILE.ENTER_APPLICATION' | translate }}</span>
  </div>
</a>
