/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '../../../logger/logger';
import { DateDisplayService } from '../../../object-viewer/services/date-display.service';
import { ComponentType } from '../../../object-viewer/types';
import { EditObject } from '../../models/edit-object.model';
import { FormControl } from '@angular/forms';
import { DatePatternConstants } from '../../../dates.constants';

@Component({
  selector: 'vitamui-common-primitive-editor',
  templateUrl: './primitive-editor.component.html',
  styleUrls: ['./primitive-editor.component.scss'],
})
export class PrimitiveEditorComponent implements OnInit {
  @Input() editObject: EditObject;

  uiComponent: ComponentType;
  dateFormat: string;

  constructor(
    private logger: Logger,
    private dateDisplayService: DateDisplayService,
  ) {}

  ngOnInit(): void {
    if (!this.editObject) return;
    if (!this.editObject.control) this.logger.warn(this, this.editObject.path, 'No control assigned !');

    this.uiComponent = this.editObject.displayRule?.ui?.component;
    this.dateFormat = this.dateDisplayService.getFormat(this.uiComponent);
  }

  protected readonly FormControl = FormControl;

  getPickerType(editObject: EditObject) {
    if (editObject.pattern) {
      if (editObject.pattern === DatePatternConstants.YEAR) {
        return 'year';
      } else if (editObject.pattern === DatePatternConstants.YEAR_MONTH_DAY) {
        return 'day';
      } else if (editObject.pattern === DatePatternConstants.YEAR_MONTH) {
        return 'month';
      }
    }
    return 'day';
  }
}
