import { forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
// @ts-ignore
import merge from 'deepmerge';
export class MultiTranslateHttpLoader {
  constructor(http, resources) {
    this.http = http;
    this.resources = resources;
  }
  getTranslation(lang) {
    const requests = this.resources.map(resource => {
      const path = resource.prefix + lang + resource.suffix;
      return this.http.get(path).pipe(catchError(res => {
        console.error("Something went wrong for the following translation file:", path);
        console.error(res.message);
        return of({});
      }));
    });
    return forkJoin(requests).pipe(map(response => merge.all(response)));
  }
}