<div
  class="vitamui-autocomplete"
  [class.disabled]="control.disabled"
  [class.label-up]="labelUp"
  vitamuiCommonToolTipClass="text-pre-line"
  vitamuiCommonToolTip="{{ this.autocompleteLabel }}"
  vitamuiCommonToolTipPosition="BOTTOM"
  vitamuiCommonToolTipShowDelay="300"
>
  <label class="input-label">
    {{ placeholder }}
    <span *ngIf="required" class="required-marker">*</span>
  </label>
  <input
    #input="matAutocompleteTrigger"
    matInput
    type="text"
    [required]="required"
    [formControl]="control"
    [matAutocomplete]="auto"
    [vitamuiAutocompletePosition]="input"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (click)="$event.target.select()"
    (input)="inputChange($event.target.value)"
  />
  <i class="material-icons">keyboard_arrow_down</i>
</div>

<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn(options)"
  (optionSelected)="selectionChange($event)"
  class="vitamui-autocomplete-panel"
>
  <ng-container *ngFor="let option of filteredOptions | async">
    <mat-option [value]="option.key">
      <span vitamuiCommonEllipsis [isToolTipOnMouseEnter]="true">{{ option.label }}</span>
      <i class="material-icons" *ngIf="option.info" [vitamuiCommonToolTip]="option.info">info_outline</i>
    </mat-option>
  </ng-container>
</mat-autocomplete>

<div class="vitamui-input-errors">
  <ng-content></ng-content>
</div>
