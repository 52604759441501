<div class="search">
  <input
    #searchInput
    [(ngModel)]="searchValue"
    (ngModelChange)="searchChanged.emit(searchValue)"
    [name]="name"
    type="text"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (keydown.enter)="onSearch()"
    (keydown.escape)="reset()"
  />
  <button *ngIf="searchValue" type="button" class="btn-reset" (click)="reset()" [disabled]="disabled">
    <i class="material-icons">clear</i>
  </button>
  <button type="button" class="btn btn-circle btn-search small" [ngClass]="searchButtonColor" (click)="onSearch()" [disabled]="disabled">
    <i class="material-icons">search</i>
  </button>
</div>
