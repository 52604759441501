<button
  mat-icon-button
  [disabled]="disabled"
  disableRipple
  [attr.aria-label]="'toggle ' + node?.label"
  (click)="nodeToggle.emit()"
  type="button"
>
  <i class="material-icons mat-icon-rtl-mirror">{{ expanded ? 'expand_more' : 'chevron_right' }}</i>
</button>

<mat-checkbox
  id="enable{{ node?.id }}"
  [(ngModel)]="node.checked"
  [(indeterminate)]="node.disabledChild"
  [disabled]="node.disabled"
  (ngModelChange)="labelClick.emit()"
  >{{ node?.label }}</mat-checkbox
>
<label for="enable{{ node?.id }}" class="node-label" (click)="labelClick.emit()"></label>
