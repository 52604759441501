/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
} from '@angular/forms';
import { Subscription } from 'rxjs';

export const VITAMUI_COMMON_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VitamUICommonInputComponent),
  multi: true,
};

@Component({
  selector: 'vitamui-common-input',
  templateUrl: './vitamui-common-input.component.html',
  styleUrls: ['./vitamui-common-input.component.scss'],
  providers: [VITAMUI_COMMON_INPUT_VALUE_ACCESSOR],
})
export class VitamUICommonInputComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  control: AbstractControl;

  @Input() type = 'text';
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() maxlength: number;
  @Input() placeholder: string;
  @Input() autofocus: boolean;
  @Input() value: string | number;

  @Input()
  get autoFocus(): boolean {
    return this._autoFocus;
  }

  set autoFocus(value: boolean) {
    this._autoFocus = coerceBooleanProperty(value);
  }

  private _autoFocus = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;
  @ViewChild('vitamUIInput') private input: ElementRef;

  @HostBinding('class.vitamui-focused') focused = false;
  @HostBinding('class.vitamui-float') labelFloat = false;

  private subscription?: Subscription;

  constructor(private injector: Injector) {}

  ngOnInit() {
    const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });

    if (ngControl instanceof NgModel) {
      this.control = ngControl.control;
      this.subscription = ngControl.control.valueChanges.subscribe((value) => {
        if (ngControl.model !== value || ngControl.viewModel !== value) {
          ngControl.viewToModelUpdate(value);
        }
      });
    } else if (ngControl instanceof FormControlDirective) {
      this.control = ngControl.control;
    } else if (ngControl instanceof FormControlName) {
      const container = this.injector.get(ControlContainer).control as FormGroup;
      this.control = container.controls[ngControl.name] as FormControl;
    } else {
      this.control = new FormControl();
    }

    this.labelFloat = !!this.value;
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 200);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  @HostListener('click')
  onClick() {
    this.input.nativeElement.focus();
  }

  writeValue(value: string | number) {
    this.value = value;
    this.labelFloat = !!this.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onTextChange(value: string) {
    this.labelFloat = !!this.value;
    this.onChange(value);
  }

  onNumberChange(value: number) {
    const badInput = this.input.nativeElement.validity.badInput;
    this.labelFloat = badInput || this.value !== null;
    this.onChange(value);
  }

  onValueChange(value: string) {
    if (this.type !== 'number' || value === '') {
      this.onTextChange(value);
    } else {
      this.onNumberChange(Number(value));
    }
  }

  onFocus() {
    this.focused = true;
    this.onTouched();
  }

  onBlur() {
    this.focused = false;
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
