<div class="download-bar-container">
  <div class="download-bar-content">
    <i class="vitamui-icon vitamui-icon-download"></i>
    <div *ngIf="isCompressed" class="download-label">
      {{ count | i18nPlural: downloadCountMap | translate: { count: count } }}
      <ng-container *ngIf="count !== total">
        {{ notDownloadable | i18nPlural: downloadCountOverTotalMap | translate: { total: total, count: notDownloadable } }}
      </ng-container>
    </div>
    <div *ngIf="!isCompressed" class="download-label">{{ 'DOWNLOAD.DOWNLOAD_FILE' | translate }} : {{ progressionValue | percent }}</div>
    <div *ngIf="progressionValue; else interdeterminateProgress" class="download-progress-bar-container">
      <mat-progress-bar class="download-progress-bar" mode="determinate" [value]="progressionInPercentage"></mat-progress-bar>
    </div>
    <ng-template #interdeterminateProgress>
      <div class="download-progress-bar-container">
        <mat-progress-bar class="download-progress-bar" mode="indeterminate"></mat-progress-bar>
      </div>
    </ng-template>
    <button class="cancel-link" (click)="confirmClose()">{{ 'DOWNLOAD.CANCEL_ACTION' | translate }}</button>
  </div>

  <button class="close-btn" (click)="confirmClose()"><i class="material-icons">clear</i></button>
</div>

<ng-template #confirmDialog>
  <mat-dialog-content>
    <p>{{ 'DOWNLOAD.CONFIRM_CANCEL_ACTION' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [matDialogClose]="true" class="btn primary">{{ 'COMMON.CONFIRM' | translate }}</button>
    <button matDialogClose class="btn cancel link ml-3 mr-1">{{ 'COMMON.BACK' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
