/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { HintComponent } from '../../components/hint/hint.component';
import { FormErrorDisplayComponent } from '../../components/form-error-display/form-error-display.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { AppendStarPipe } from '../required.pipe';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

@Component({
  selector: 'vitamui-editor-select',
  template: `
    <mat-form-field class="mb-4 w-100 vitamui-mat-select">
      <mat-label>{{ label | translate | empty }}</mat-label>
      <mat-select [formControl]="control" [multiple]="multiple" panelClass="vitamui-mat-select">
        @for (option of options; track option) {
          <mat-option [value]="option">
            {{ option }}
          </mat-option>
        }
      </mat-select>
      <div class="select-arrow">
        <i class="material-icons">keyboard_arrow_up</i>
        <i class="material-icons">keyboard_arrow_down</i>
      </div>
      <vitamui-hint [control]="control" [hint]="hint"></vitamui-hint>
      <vitamui-form-error-display [control]="control"></vitamui-form-error-display>
    </mat-form-field>
  `,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    HintComponent,
    FormErrorDisplayComponent,
    TranslateModule,
    PipesModule,
    AppendStarPipe,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
  ],
})
export class EditorSelectComponent {
  @Input({ required: true }) control!: FormControl;
  @Input() options: string[] = [];
  @Input() multiple: boolean = false;
  @Input() required: boolean = false;
  @Input() label?: string;
  @Input() hint?: string;
}
