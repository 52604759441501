<div class="d-flex">
  <div *ngIf="photo; then userPhoto; else defaultUserPhoto" (click)="photoClicked.emit()"></div>
  <span *ngIf="hasStatus" [ngClass]="statusCondition ? 'status-badge-green' : 'status-badge-grey'"></span>
</div>

<ng-template #defaultUserPhoto>
  <img class="user-photo" [src]="userDefaultLogoUrl" [style.width.px]="size" alt="user photo" />
</ng-template>

<ng-template #userPhoto>
  <img class="user-photo" [src]="photo" [style.width.px]="size" alt="user photo" />
</ng-template>
