<div class="editable-field-wrapper" *ngIf="!disabled">
  <div cdkOverlayOrigin #origin="cdkOverlayOrigin">
    <div class="vitamui-input editable-field" [class.edit-mode]="editMode" (click)="enterEditMode()">
      <div class="editable-field-content">
        <label>{{ label }}</label>
        <span class="editable-field-text-content">{{ content(control?.value) }}</span>
        <div class="editable-field-control">
          <mat-button-toggle-group [formControl]="control" class="vitamui-button-toggle-group">
            <mat-button-toggle *ngFor="let button of buttons" [value]="button?.value">{{ button?.content }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <i *ngIf="!showSpinner && !editMode" class="material-icons edit-icon">edit</i>
      <div *ngIf="showSpinner">
        <mat-spinner diameter="24" color="accent"></mat-spinner>
      </div>
    </div>
  </div>

  <div class="vitamui-input-errors"></div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOpen]="editMode"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayPositions]="positions"
  >
    <div class="editable-field-actions">
      <button type="button" class="editable-field-confirm" (click)="confirm()" [disabled]="!canConfirm">
        <i class="material-icons">check</i>
      </button>
      <button type="button" class="editable-field-cancel" (click)="cancel()"><i class="material-icons">clear</i></button>
    </div>
  </ng-template>
</div>

<div *ngIf="disabled" class="read-only-field">
  <label>{{ label }}</label>
  <div>{{ content(control?.value) }}</div>
</div>
