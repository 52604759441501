<div class="editable-field-wrapper" *ngIf="!disabled">
  <div cdkOverlayOrigin #origin="cdkOverlayOrigin">
    <div class="vitamui-input editable-field" [class.edit-mode]="editMode" (click)="enterEditMode()">
      <div class="editable-field-content">
        <label>{{ label }}</label>
        <span class="editable-field-text-content">{{ control?.value?.name }}</span>
        <div class="editable-field-control">
          <div class="vitamui-input-file">
            <label [for]="customId">
              <span class="text primary underline">{{ 'SHARED.EDITABLE_FIELD.UPLOAD_FILE' | translate }}</span>
              <div class="vitamui-input-file-filename">{{ file?.name }}</div>
            </label>
            <input [id]="customId" type="file" [accept]="accept" (change)="setFile($event.target.files)" #fileInput />
          </div>
        </div>
      </div>
      <i *ngIf="!showSpinner && !editMode" class="material-icons edit-icon">edit</i>
      <div *ngIf="showSpinner">
        <mat-spinner diameter="24" color="accent"></mat-spinner>
      </div>
    </div>
  </div>

  <div class="vitamui-input-errors">
    <ng-content select="vitamui-common-field-error"></ng-content>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOpen]="editMode"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayPositions]="positions"
  >
    <div class="editable-field-actions">
      <button type="button" class="editable-field-confirm" (click)="confirm()" [disabled]="!canConfirm">
        <i class="material-icons">check</i>
      </button>
      <button type="button" class="editable-field-cancel" (click)="cancel()"><i class="material-icons">clear</i></button>
    </div>
  </ng-template>
</div>

<div *ngIf="disabled" class="read-only-field">
  <label>{{ label }}</label>
  <div>{{ control?.value?.name }}</div>
</div>
