<div class="vitamui-snack-bar-container">
  <div class="vitamui-snack-bar-content">
    <i *ngIf="data?.icon" class="vitamui-icon {{ data.icon }}"></i>
    <div class="d-flex align-items-center" [class.clickable]="data.callBack">
      <div [innerHTML]="data.message"></div>
      <div *ngIf="data.htmlContent" class="ml-2" (click)="callBack()" [innerHTML]="html"></div>
    </div>
  </div>

  <button (click)="close()" class="close-btn">
    <i class="material-icons">clear</i>
  </button>
</div>
