<div class="editable-field-wrapper" *ngIf="!disabled && !writeDisabled">
  <div cdkOverlayOrigin #origin="cdkOverlayOrigin">
    <div class="vitamui-input editable-field" [class.edit-mode]="editMode" (click)="enterEditMode()">
      <div class="editable-field-content">
        <label>{{ label }}</label>
        <span class="editable-field-text-content">{{ control?.value }}</span>
        <div class="editable-field-control">
          <input type="number" [formControl]="control" [maxlength]="maxlength" #input *ngIf="type === 'number'" />
          <input [type]="type" [formControl]="control" [maxlength]="maxlength" #input *ngIf="type !== 'number'" />
        </div>
      </div>
      <i *ngIf="!showSpinner && !editMode" class="material-icons edit-icon">edit</i>
      <div *ngIf="showSpinner">
        <mat-spinner diameter="24" color="accent"></mat-spinner>
      </div>
    </div>
  </div>

  <div class="vitamui-input-errors">
    <ng-content select="vitamui-common-field-error"></ng-content>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOpen]="editMode"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayPositions]="positions"
  >
    <div class="editable-field-actions">
      <button type="button" class="editable-field-confirm" (click)="confirm()" [disabled]="!canConfirm">
        <i class="material-icons">check</i>
      </button>
      <button type="button" class="editable-field-cancel" (click)="cancel()"><i class="material-icons">clear</i></button>
    </div>
  </ng-template>
</div>

<div *ngIf="disabled" class="read-only-field">
  <label>{{ label }}</label>
  <div vitamuiCommonEllipsis class="value-display">{{ control?.value }}</div>
</div>

<div *ngIf="writeDisabled" class="read-only-field-design">
  <label>{{ label }}</label>
  <div class="value-display">{{ control?.value }}</div>
</div>
