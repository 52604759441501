<button
  mat-icon-button
  [disabled]="disabled"
  disableRipple
  [attr.aria-label]="'toggle ' + node?.label"
  (click)="nodeToggle.emit()"
  class="arrow"
  type="button"
>
  <i class="material-icons mat-icon-rtl-mirror">{{ expanded ? 'expand_more' : 'chevron_right' }}</i>
</button>

<mat-checkbox
  id="enable{{ node?.id }}"
  [(ngModel)]="node.checked"
  [(indeterminate)]="node.disabledChild"
  [disabled]="node.disabled"
  (ngModelChange)="onCheckboxClick()"
  (click)="onCheckboxClick()"
  [vitamuiCommonToolTip]="node?.title"
  [vitamuiCommonToolTipShowDelay]="300"
>
  <label class="node-label" (click)="onLabelClick($event)">
    <i class="pad vitamui-icon {{ icon }}"></i>
    {{ node?.title }}
  </label>
</mat-checkbox>
