/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { ENTER } from '@angular/cdk/keycodes';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

export const EMAILS_INPUT_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EmailsInputComponent),
  multi: true,
};

@Component({
  selector: 'vitamui-common-emails-input',
  templateUrl: './emails-input.component.html',
  styleUrls: ['./emails-input.component.scss'],
  providers: [EMAILS_INPUT_ACCESSOR],
})
export class EmailsInputComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() spinnerDiameter = 25;

  emails: string[] = [];
  control: FormControl;
  separatorKeysCodes = [ENTER];

  onChange: (_: any) => void;
  onTouched: () => void;

  constructor() {
    this.control = new FormControl('', [Validators.pattern(/^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/)], null);
  }

  writeValue(emails: string[]) {
    this.emails = (emails || []).slice();
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  add(): void {
    if (this.control.invalid || this.control.pending) {
      return;
    }
    const email = this.control.value.trim();
    if (this.emails.includes(email)) {
      return;
    }
    this.emails.push(email);
    this.onChange(this.emails);
    this.control.reset();
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.onChange(this.emails);
    }
  }

  buttonAddDisabled(): boolean {
    return !this.control.value || this.control.pending || this.control.invalid || this.emailExists;
  }

  get emailExists(): boolean {
    return this.emails.includes((this.control.value || '').trim());
  }
}
