<ng-container *ngIf="editObject?.control && editObject.cardinality !== 'ZERO'">
  <ng-container [ngSwitch]="uiComponent">
    <vitamui-editor-input
      *ngSwitchCase="'textfield'"
      [control]="editObject.control"
      [label]="editObject.displayRule?.ui?.label"
      [hint]="editObject.hint"
      [required]="editObject.required"
    ></vitamui-editor-input>
    <vitamui-editor-textarea
      *ngSwitchCase="'textarea'"
      [control]="editObject.control"
      [label]="editObject.displayRule?.ui?.label"
      [hint]="editObject.hint"
      [required]="editObject.required"
    ></vitamui-editor-textarea>
    <vitamui-editor-select
      *ngSwitchCase="'select'"
      [control]="editObject.control"
      [label]="editObject.displayRule?.ui?.label"
      [options]="editObject.options"
      [hint]="editObject.hint"
      [multiple]="editObject.cardinality.includes('MANY')"
      [required]="editObject.required"
    ></vitamui-editor-select>
    <vitamui-common-multiple-options-datepicker
      *ngSwitchCase="'datepicker'"
      [formControl]="editObject.control"
      [label]="editObject.displayRule.ui.label | translate | empty | appendStar: editObject.required"
      [pickerType]="getPickerType(editObject)"
    >
      <vitamui-hint [control]="editObject.control" [hint]="editObject.hint"></vitamui-hint>
      <vitamui-form-error-display [control]="editObject.control"></vitamui-form-error-display>
    </vitamui-common-multiple-options-datepicker>
    <vitamui-common-multiple-options-datepicker
      *ngSwitchCase="'datetime'"
      [formControl]="editObject.control"
      [label]="editObject.displayRule.ui.label | translate | empty | appendStar: editObject.required"
      [pickerType]="getPickerType(editObject)"
    >
      <vitamui-hint [control]="editObject.control" [hint]="editObject.hint"></vitamui-hint>
      <vitamui-form-error-display [control]="editObject.control"></vitamui-form-error-display>
    </vitamui-common-multiple-options-datepicker>
  </ng-container>
</ng-container>
