<div class="parent-label d-flex align-items-center">
  <i class="material-icons">chevron_right</i>
  <div>
    <span [isToolTipOnMouseEnter]="true" vitamuiCommonEllipsis>{{ node?.name }}</span>
  </div>
</div>
<ul>
  <li *ngFor="let node of node?.child">
    <vitamui-common-display-node [node]="node"></vitamui-common-display-node>
  </li>
</ul>
