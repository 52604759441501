<div class="d-flex align-items-center px-1">
  <div class="search">
    <input
      #searchInput
      [(ngModel)]="searchValue"
      (ngModelChange)="searchChanged.emit(searchValue)"
      [name]="name"
      type="text"
      [placeholder]="placeholder"
      [disabled]="disabled"
    />
    <button *ngIf="searchValue" type="button" class="btn-reset" (click)="reset()" [disabled]="disabled">
      <i class="material-icons">clear</i>
    </button>
  </div>

  <button class="btn primary ml-5" (click)="onSearch()" [disabled]="disabled">
    <span>{{ buttonLabel }}</span>
  </button>
  <ng-content></ng-content>
</div>
