<mat-spinner *ngIf="pending" diameter="20" color="accent" class="spinner-wrapper"></mat-spinner>
<ng-container *ngIf="!pending">
  <a
    *ngIf="canLoadExactCount"
    [vitamuiCommonToolTip]="'ARCHIVE_SEARCH.RETRIEVE_EXACT_COUNT' | translate"
    [vitamuiCommonToolTipShowDelay]="300"
    class="link-without-underline"
    (click)="loadExactCount()"
    >{{ 'ARCHIVE_SEARCH.MORE_THAN' | translate }} {{ threshold }}
  </a>
  <span *ngIf="!canLoadExactCount">{{ archiveUnitCount }}</span>
  <span>
    {{ 'ARCHIVE_SEARCH.RESULTS' | translate }} /
    {{ (displaySelectedArchiveUnitCount ? '' : 'ARCHIVE_SEARCH.MORE_THAN') | translate }}
    {{ displaySelectedArchiveUnitCount ? selectedArchiveUnitCount : threshold }}
    {{ 'ARCHIVE_SEARCH.ONE_SELECTED' | translate | plural: selectedArchiveUnitCount }}
  </span>
</ng-container>
