<label>
  <div
    class="slide-toggle"
    [ngClass]="{ 'slide-toggle-on': checked, 'slide-toggle-off': !checked }"
    [class.slide-toggle-on]="checked"
    [class.slide-toggle-disabled]="disabled"
  >
    <input
      class="cdk-visually-hidden"
      [required]="required"
      [tabIndex]="tabIndex"
      [checked]="checked"
      [disabled]="disabled"
      [attr.name]="name"
      type="checkbox"
      (click)="toggle()"
      (blur)="onTouched()"
    />
    <div class="slide-toggle-inner d-flex">
      <div class="slide-toggle-label"></div>
      <div class="slide-toggle-thumb"></div>
      <div class="slide-toggle-label"></div>
    </div>
  </div>
  <span><ng-content></ng-content></span>
</label>
