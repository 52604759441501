<div class="d-flex align-items-center pl-3 mr-4 site-label" extended-content [matMenuTriggerFor]="siteMenu">
  <div class="mx-2">
    <div class="text caption bold">
      {{ 'Site observé' }}
    </div>
    <div class="text normal bold">
      {{ selectedSite ? selectedSite.name + ' - ' + selectedSite.code : '' }}
    </div>
  </div>
  <div class="ml-3">
    <i class="vitamui-icon vitamui-icon-chevron-down"></i>
  </div>
  <mat-menu #siteMenu="matMenu" [overlapTrigger]="false" xPosition="before">
    <ng-container *ngFor="let site of sites">
      <button mat-menu-item (click)="onSelectSite(site)">{{ site.code + ' - ' + site.name }}</button>
    </ng-container>
  </mat-menu>
</div>
