<div class="content row">
  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
    <h3>{{ title }}</h3>

    <div class="text medium">
      {{ message }}
    </div>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex align-items-center justify-content-end">
    <div class="compagnyLogo d-sm-none d-lg-block">
      <img alt="compagny logo" [src]="imgUrl" />
    </div>
  </div>
</div>
