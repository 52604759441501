/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2022)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { ResultFacet, ResultFacetList } from './search-criteria.interface';

export class FacetsUtils {
  public static RULES_COMPUTED_NUMBER_PREFIX = 'RULES_COMPUTED_NUMBER_';
  public static FINAL_ACTION_COMPUTED_PREFIX = 'FINAL_ACTION_COMPUTED_';
  public static EXPIRED_RULES_COMPUTED_PREFIX = 'EXPIRED_RULES_COMPUTED_';
  public static UNEXPIRED_RULES_COMPUTED_PREFIX = 'UNEXPIRED_RULES_COMPUTED_';
  public static COUNT_WITHOUT_RULES_PREFIX = 'COUNT_WITHOUT_RULES_';
  public static COMPUTE_RULES_AU_NUMBER = 'COMPUTE_RULES_AU_NUMBER';
  public static COUNT_BY_NODE = 'COUNT_BY_NODE';

  public static extractNodesFacetsResults(facetResults: ResultFacetList[]): ResultFacet[] {
    const nodesFacets: ResultFacet[] = [];

    if (facetResults && facetResults.length > 0) {
      for (const facet of facetResults) {
        if (facet.name === FacetsUtils.COUNT_BY_NODE) {
          for (const bucket of facet.buckets) {
            nodesFacets.push({ node: bucket.value, count: bucket.count });
          }
        }
      }
    }
    return nodesFacets;
  }
}
