<div *ngIf="!hidden && show" class="subrogation-banner">
  <div>
    <i class="vitamui-icon vitamui-icon-repeat mr-4"></i>
    <span>{{
      'SUBROGATION.BANNER.MESSAGE'
        | translate
          : {
              email: authService?.user?.email,
              surrogateCustomerName: surrogateCustomerName,
              surrogateCustomerCode: surrogateCustomerCode,
              endDate: endDate | date: 'HH:mm:ss',
            }
    }}</span>
    <button class="btn link ml-5" (click)="onStopSubrogation()">{{ 'SUBROGATION.BANNER.STOP_LABEL' | translate }}</button>
  </div>

  <div (click)="hidden = true">
    <i class="vitamui-icon vitamui-icon-close clickable"></i>
  </div>
</div>

<div *ngIf="hidden && show" class="hidden-banner-div" (click)="hidden = false">
  <i class="vitamui-icon vitamui-icon-chevron-up"></i>
</div>
