<button
  #trigger="matMenuTrigger"
  [matMenuTriggerFor]="moreMenu"
  [ngClass]="{
    'outline-secondary': outlineSecondary,
    'btn-circle': this.icon && !this.label,
    hidden: trigger.menuOpen,
  }"
  [disabled]="disabled"
  aria-label="more menu"
  [class]="menuButtonSize"
  class="btn secondary"
>
  <i *ngIf="icon" class="vitamui-icon {{ icon }}" [class.mr-3]="label"></i>
  {{ label }}
</button>

<mat-menu
  #moreMenu="matMenu"
  xPosition="{{ overlayPos === 'start' ? 'after' : 'before' }}"
  [overlapTrigger]="true"
  class="more-action-menu {{ overlayPos }}"
>
  <button mat-menu-item disableRipple class="menu-cancel-button">
    <i class="vitamui-icon vitamui-icon-close"></i>
  </button>
  <ng-content></ng-content>
</mat-menu>
