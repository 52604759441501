<div class="portal-app">
  <vitamui-common-header [hasLangSelection]="hasLangSelection"></vitamui-common-header>

  <vitamui-common-body>
    <router-outlet></router-outlet>
  </vitamui-common-body>

  <vitamui-common-footer></vitamui-common-footer>

  <vitamui-common-subrogation-banner></vitamui-common-subrogation-banner>
</div>
