<div class="row">
  <div class="col-12 mb-3">
    <label>{{ label }} <span *ngIf="required" class="required-marker">*</span></label>
  </div>
  <div class="col-12">
    <div class="radio-group">
      <ng-content select="vitamui-radio"> </ng-content>
    </div>
  </div>
</div>
